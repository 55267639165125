<template>
	<v-card width="400" height="300" class="mx-auto mt-16">
		<v-card-title class="justify-center">Quickbooks Authentication</v-card-title>
		<v-card-text class="text-center">

			<v-progress-circular
				:size="70"
				:width="7"
				color="green"
				indeterminate
				v-if="isLoading"
			></v-progress-circular>

			<v-btn
				link
				@click="getQuickbooksLoginUrl"
				color="primary"
				block class="my-10 text-capitalize"
				:disabled="isLoading"
			>
				<v-icon>mdi-key</v-icon> Signin to QB
			</v-btn>

			<v-alert v-if="isErrorFetchingSignInUrl" 
				color="red lighten-2"
				dark
			>
				<v-icon>mdi-close</v-icon> Unable to signin right now.
			</v-alert>
		</v-card-text>
	</v-card>
</template>

<script>
import {  getEnv } from '@/utils/env';
import { mapState } from 'vuex';
import axios from 'axios';

export default {
    computed: {
			...mapState('user', ['authTokenDetails']),
		},
		data() {
			return {
				isErrorFetchingSignInUrl: false,
				isLoading: false,
			}
		},
		methods: {
			async getQuickbooksLoginUrl() {
				this.isErrorFetchingSignInUrl = false;
				this.isLoading = true;
				try {
					//Temporary. Replace with this.user.authTokenDetails().
					const token = this.authTokenDetails.accessToken;
					const { data } = await axios.post( getEnv('VUE_APP_SHIFL_BASE_URL') + '/v1/quickbooks/connect', {}, {
						headers: {
							Authorization: `Bearer ${token}`
						}
					});

					console.log(data);

					// Determine here if the returned data contains URL
					if(data.data && data.data.authUrl) {
						const signInUrl = data.data.authUrl;
						window.open(signInUrl, "_self");
					}

					this.isLoading = false;

				} catch(error) {
					this.isErrorFetchingSignInUrl = true;
					this.isLoading = false;
				}
			},

		}
}
</script>

<style lang="scss">

</style>